// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-products-js": () => import("/opt/build/repo/src/templates/products.js" /* webpackChunkName: "component---src-templates-products-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-future-js": () => import("/opt/build/repo/src/pages/future.js" /* webpackChunkName: "component---src-pages-future-js" */),
  "component---src-pages-glossary-js": () => import("/opt/build/repo/src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-catch-js": () => import("/opt/build/repo/src/pages/our-catch.js" /* webpackChunkName: "component---src-pages-our-catch-js" */),
  "component---src-pages-past-js": () => import("/opt/build/repo/src/pages/past.js" /* webpackChunkName: "component---src-pages-past-js" */),
  "component---src-pages-pot-fishing-js": () => import("/opt/build/repo/src/pages/pot-fishing.js" /* webpackChunkName: "component---src-pages-pot-fishing-js" */),
  "component---src-pages-present-js": () => import("/opt/build/repo/src/pages/present.js" /* webpackChunkName: "component---src-pages-present-js" */),
  "component---src-pages-the-kawatea-js": () => import("/opt/build/repo/src/pages/the-kawatea.js" /* webpackChunkName: "component---src-pages-the-kawatea-js" */),
  "component---src-pages-traceability-js": () => import("/opt/build/repo/src/pages/traceability.js" /* webpackChunkName: "component---src-pages-traceability-js" */)
}

